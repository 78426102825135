import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Moment from "moment"
import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
Moment.locale("en")

const ClinicEventDetailPage = ({ data }) => {
  const page = data.wagtail.page

  let ancestor = { children: [], button: {} }
  page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <Layout
      isContent={true}
      subMenu={ancestor.children}
      button={ancestor.button}
      parent={"officials"}
      siteTitle={"Clinics"}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/officials">Officials</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/officials/${page.ancestors[0].slug}`}>
                      {page.ancestors[0].title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-area event">
        <div className="tab-content-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="contact">
                      <h1>{page.title}</h1>
                      <div
                        className="c-title-content mb-80"
                        dangerouslySetInnerHTML={{ __html: page.bodySafe }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-7">
                    <div className="evrnt-logation">
                      {page.clinicDate && (
                        <div className="single-address">
                          <h6>Date</h6>
                          <p>
                            {Moment(page.clinicDate.split("T")[0]).format(
                              "MMM D, YYYY"
                            )}
                          </p>
                        </div>
                      )}
                      {page.clinicDate && (
                        <div className="single-address">
                          <h6>Time</h6>
                          <p>
                            {page.initialtime &&
                              Moment(
                                Moment(page.clinicDate).format("YYYY-MM-DD") +
                                  " " +
                                  page.initialtime
                              ).format("hh:mm A")}
                            {" - "}
                            {page.endtime &&
                              Moment(
                                Moment(page.clinicDate).format("YYYY-MM-DD") +
                                  " " +
                                  page.endtime
                              ).format("hh:mm A")}
                          </p>
                        </div>
                      )}
                      {page.clubname && (
                        <div className="single-address">
                          <h6>Host</h6>
                          <p>{page.host}</p>
                        </div>
                      )}
                      {page.region && (
                        <div className="single-address">
                          <h6>Region</h6>
                          <p>{page.region}</p>
                        </div>
                      )}
                      {page.location && (
                        <div className="single-address">
                          <h6>Location</h6>
                          <p>{page.location}</p>
                        </div>
                      )}
                      {(page.contactname || page.const) && (
                        <div className="single-address">
                          <h6>Contact</h6>
                          {page.contactname && <p>{page.contactname}</p>}
                          {page.contactemail && (
                            <p>
                              <a
                                href={`mailto:${page.contactemail}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {page.contactemail}
                              </a>
                            </p>
                          )}
                        </div>
                      )}
                      {page.description && (
                        <div className="single-address">
                          <h6>Additional Information</h6>
                          <p>{page.description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-5">
                    {page.region &&
                      page.location &&
                      page.imageMaps &&
                      page.region.toLowerCase() !== "webinar" && (
                        <div className="map">
                          <a
                            href={`https://maps.google.com?near=${page.location}`}
                            target="_blank"
                          >
                          <img src={`https://admin.swimontario.com/${page.imageMaps}`} />
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]} />
        )}
    </Layout>
  )
}
ClinicEventDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}
export const query = graphql`
  query($id: Int) {
    wagtail {
      page(id: $id) {
        ... on EventPage {
          id
          title
          bodySafe
          clinicDate
          clinicname
          clubname
          host
          region
          location
          imageMaps
          initialtime
          endtime
          contactname
          contactemail
          description
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors(order: "-depth") {
            title
            depth
            slug
            ... on OfficialsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on PositionsPage {
                  live
                  isProtected
                }
                ... on OfficialPage {
                  live
                  isProtected
                }
                ... on BulletinsPage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
        }
      }
    }
  }
`
export default ClinicEventDetailPage
